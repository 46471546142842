import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AbstractComponent, Channel, ChannelService, Department, DepartmentService, User, UserService, ReportService, Pager, Attendance, ModalReportAttendanceComponent, ExportService } from 'lib-trend-core';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { saveAs } from 'file-saver';

interface ResponseCountItemsAttendanceTypes {
  totalDepartments: number;
  totalAttendanceCurrent: number;
  totalAttendancePrevious: number;
  totalUsers: number;
  totalChannels: number;
  totalChannelIds: string[];
  totalDepartmentIds: string[];
  totalUserIds: string[];
  attendanceIdsCurrent: string[];
  attendanceIdsPrevious: string[];
}

interface IdsTypes {
  user: string;
  channel: string;
  department: string;
}
interface ResponsePerformeceAttendanceTypes {
  department: string;
  totalAttendanceCurrent: number;
  totalAttendancePrevious: number;
  channel: string;
  user: string;
  createdAt: string;
  _id?: IdsTypes[];
}

interface ResponseGraphicUserTypes {
  totalUser: number;
  user: string;
}

interface ResponseGraphicChannelTypes {
  totalChannel: number;
  channel: string;
}

interface ResponseGraphicDepartmentTypes {
  totalDepartment: number;
  department: string;
}

interface ResponseGraphicTypes {
  name: string;
  value: number;
}

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrl: './report-list.component.scss'
})
export class ReportListComponent extends AbstractComponent implements OnInit {
  @ViewChild('picker', { static: true }) picker: MatDatepicker<Date>;
  reportForm: any;
  userAttendant: User[];
  department: Department[];
  channelAttendant: Channel[];
  countItemsAttendance: ResponseCountItemsAttendanceTypes;
  graphicChannel: ResponseGraphicTypes[];
  graphicUser: ResponseGraphicTypes[];
  graphicDepartment: ResponseGraphicTypes[];
  pager: Pager<ResponsePerformeceAttendanceTypes> = new Pager<ResponsePerformeceAttendanceTypes>({ perPage: 10 });
  title: string;
  colorScheme: any = {
    domain: ['#aa98ec', '#4213f6']
  };
  selectedGraphic: string = 'channel';
  selectedGraphicLabel: string = 'Gráfico';
  selectedList: string = 'user';
  selectedListLabel: string = 'Lista';

  view: [number, number] = [600, 400]

  typeCharts = [
    {
      name: 'Canal',
      value: 'channel'
    },
    {
      name: 'Atendente',
      value: 'user'
    },
    {
      name: 'Departamento',
      value: 'department'
    },
  ];

  labelChannel: string = 'Canal';
  labelDepartment: string = 'Departamento';
  labelAttendant: string = 'Atendente';
  labelGraphic: string = 'Gráfico'


  constructor(
    injetor: Injector,
    private fb: FormBuilder,
    public userSerivce: UserService,
    public departmentService: DepartmentService,
    public channelService: ChannelService,
    public reportService: ReportService,
    public exportService: ExportService,
    public override router: Router,
    public dialog: MatDialog,
  ) {
    super(injetor);
    this.loadingContent = true;
  }

  ngOnInit(): void {
    this.createForm();
    this.getByUserAttendant();
    this.getDepartmentByCompany();
    this.getByAllChannel();
    this.initializeData();


    this.route.queryParams.subscribe(params => {
      this.title = params['title'];
    });

    this.reportForm.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    ).subscribe(val => {
      this.onFormChange(val);
    });
  }

  private initializeData() {
    const formsValue = {
      dateStartCurrent: this.reportForm.value.dateRange.dateStartCurrent,
      dateEndCurrent: this.reportForm.value.dateRange.dateEndCurrent,
      dateStartPrevious: this.reportForm.value.dateRange.dateStartPrevious,
      dateEndPrevious: this.reportForm.value.dateRange.dateEndPrevious,
      department: this.reportForm.value.department === -1 ? null : this.reportForm.value.department,
      attendant: this.reportForm.value.attendant === -1 ? null : this.reportForm.value.attendant,
      channel: this.reportForm.value.channel === -1 ? null : this.reportForm.value.channel
    };
    this.getCountAttendanceItems(formsValue);
    this.getCountAttendanceItemsPerformece(formsValue);
    this.getGraphicChannelAttendance(formsValue);
    this.getGraphicAttendantAttendance(formsValue);
    this.getGraphicDepartmentAttendance(formsValue);
  }
  private createForm(): void {
    const today = new Date();
    const yesterday = new Date(today);
    const todayDay = today.getDate();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();

    const lastMonthSameDay = new Date(todayYear, todayMonth - 1, todayDay);
    const lastMonthPreviousDay = new Date(todayYear, todayMonth - 1, todayDay-1);

    yesterday.setDate(today.getDate() - 1);
    this.reportForm = this.fb.group({
      dateRange: this.fb.group({
        dateStartCurrent: [yesterday, []],
        dateEndCurrent: [today, []],
        dateStartPrevious: [lastMonthPreviousDay, []],
        dateEndPrevious: [lastMonthSameDay, []],
      }),
      department: [-1],
      attendant: [-1],
      channel: [-1]
    });
  }

  onFormChange(changes: any) {
    if (changes.dateRange.dateStartCurrent && changes.dateRange.dateEndCurrent ||
      changes.dateRange.dateStartPrevious && changes.dateRange.dateEndPrevious) {
      const formsValue = {
        dateStartCurrent: this.reportForm.value.dateRange.dateStartCurrent,
        dateEndCurrent: this.reportForm.value.dateRange.dateEndCurrent,
        dateStartPrevious: this.reportForm.value.dateRange.dateStartPrevious,
        dateEndPrevious: this.reportForm.value.dateRange.dateEndPrevious,
        department: changes.department === -1 ? null : changes.department,
        attendant: changes.attendant === -1 ? null : changes.attendant,
        channel: changes.channel === -1 ? null : changes.channel
      }
      this.getCountAttendanceItems(formsValue);
      this.getCountAttendanceItemsPerformece(formsValue);
      this.getGraphicChannelAttendance(formsValue);
      this.getGraphicAttendantAttendance(formsValue);
      this.getGraphicDepartmentAttendance(formsValue);
    }
  }

  onPeriodChangeCurrent(event: any) {
    const today = new Date();
    switch (event.value) {
      case '1':
        this.reportForm.get('dateRange').patchValue({
          dateStartCurrent: new Date(),
          dateEndCurrent: new Date()
        });
        this.picker.close();
        break;
      case '7':
        this.reportForm.get('dateRange').patchValue({
          dateStartCurrent: new Date(today.setDate(today.getDate() - 7)),
          dateEndCurrent: new Date()
        });
        this.picker.close();
        break;
      case '15':
        this.reportForm.get('dateRange').patchValue({
          dateStartCurrent: new Date(today.setDate(today.getDate() - 15)),
          dateEndCurrent: new Date()
        });
        this.picker.close();
        break;
      case '30':
        this.reportForm.get('dateRange').patchValue({
          dateStartCurrent: new Date(today.setDate(today.getDate() - 30)),
          dateEndCurrent: new Date()
        });
        this.picker.close();
        break;
      case '60':
        this.reportForm.get('dateRange').patchValue({
          dateStartCurrent: new Date(today.setDate(today.getDate() - 60)),
          dateEndCurrent: new Date()
        });
        this.picker.close();
        break;
    }
  }

  onPeriodChangePrevious(event: any) {
    const today = new Date();
    switch (event.value) {
      case '1':
        this.reportForm.get('dateRange').patchValue({
          dateStartPrevious: new Date(),
          dateEndPrevious: new Date()
        });
        this.picker.close();
        break;
      case '7':
        this.reportForm.get('dateRange').patchValue({
          dateStartPrevious: new Date(today.setDate(today.getDate() - 7)),
          dateEndPrevious: new Date()
        });
        this.picker.close();
        break;
      case '15':
        this.reportForm.get('dateRange').patchValue({
          dateStartPrevious: new Date(today.setDate(today.getDate() - 15)),
          dateEndPrevious: new Date()
        });
        this.picker.close();
        break;
      case '30':
        this.reportForm.get('dateRange').patchValue({
          dateStartPrevious: new Date(today.setDate(today.getDate() - 30)),
          dateEndPrevious: new Date()
        });
        this.picker.close();
        break;
      case '60':
        this.reportForm.get('dateRange').patchValue({
          dateStartPrevious: new Date(today.setDate(today.getDate() - 60)),
          dateEndPrevious: new Date()
        });
        this.picker.close();
        break;
    }
  }

  loadPage(page: number) {
    this.pager.page = page;
    const formsValue = {
      dateStartCurrent: this.reportForm.value.dateRange.dateStartCurrent,
      dateEndCurrent: this.reportForm.value.dateRange.dateEndCurrent,
      dateStartPrevious: this.reportForm.value.dateRange.dateStartPrevious,
      dateEndPrevious: this.reportForm.value.dateRange.dateEndPrevious,
      department: this.reportForm.value.department === -1 ? null : this.reportForm.value.department,
      attendant: this.reportForm.value.attendant === -1 ? null : this.reportForm.value.attendant,
      channel: this.reportForm.value.channel === -1 ? null : this.reportForm.value.channel,
    };
    this.getCountAttendanceItemsPerformece(formsValue);
  }

  getByUserAttendant() {
    this.userSerivce.findByAttendant().subscribe({
      next: (users: User[]) => {
        this.userAttendant = users;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getDepartmentByCompany() {
    this.departmentService.getList().subscribe({
      next: (dresponseDepart: Department[]) => {
        this.department = dresponseDepart;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getByAllChannel() {
    this.channelService.getByAllChannel().subscribe({
      next: (channel: Channel[]) => {
        this.channelAttendant = channel;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getCountAttendanceItems(form) {
    this.reportService.getByAttendanceCountItems(form).subscribe({
      next: (reportCount: ResponseCountItemsAttendanceTypes) => {
        this.countItemsAttendance = reportCount;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getCountAttendanceItemsPerformece(form) {
    this.reportService.getByAttendanceCountItemsPerformece({ ...form, page: this.pager.page, limit: this.pager.perPage }).subscribe({
      next: (reportPerformece) => {
        this.pager = reportPerformece;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getGraphicChannelAttendance(form) {
    this.reportService.getGraphicChannelAttendance(form).subscribe({
      next: (graphicChannel: ResponseGraphicChannelTypes[]) => {
        this.graphicChannel = graphicChannel.map((item) => {
          return {
            name: item.channel,
            value: item.totalChannel
          }
        });
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getGraphicAttendantAttendance(form) {
    this.reportService.getGraphicAttendantAttendance(form).subscribe({
      next: (graphicUser: ResponseGraphicUserTypes[]) => {
        this.graphicUser = graphicUser.map((item) => {
          return {
            name: item.user ?  item.user : '',
            value: item.totalUser
          }
        });
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getGraphicDepartmentAttendance(form) {
    this.reportService.getGraphicDepartmentAttendance(form).subscribe({
      next: (graphicDepartment: ResponseGraphicDepartmentTypes[]) => {
        this.graphicDepartment = graphicDepartment.map((item) => {
          return {
            name: item.department,
            value: item.totalDepartment
          }
        });
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  exportFile() {
    const formsValue = {
      dateStartCurrent: this.reportForm.value.dateRange.dateStartCurrent,
      dateEndCurrent: this.reportForm.value.dateRange.dateEndCurrent,
      dateStartPrevious: this.reportForm.value.dateRange.dateStartPrevious,
      dateEndPrevious: this.reportForm.value.dateRange.dateEndPrevious,
      department: this.reportForm.value.department === -1 ? null : this.reportForm.value.department,
      attendant: this.reportForm.value.attendant === -1 ? null : this.reportForm.value.attendant,
      channel: this.reportForm.value.channel === -1 ? null : this.reportForm.value.channel,
    };
    this.reportService.getAttendanceItemsPerformeceExport(formsValue).subscribe({
      next: async (exportPerformance) => {
        const bufferExport = await this.exportService.generateExcel(exportPerformance);
        const blob = new Blob([bufferExport], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        saveAs(blob, 'relatorio-performance.xlsx');
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  openModalCountAttendance(type: string, data: any[]): void {
    const refBottom = this.dialog.open(ModalReportAttendanceComponent, {
      width: '50rem', height: '40rem', data: {
        type,
        data,
        dateStartCurrent: this.reportForm.value.dateRange.dateStartCurrent,
        dateEndCurrent: this.reportForm.value.dateRange.dateEndCurrent,
        dateStartPrevious: this.reportForm.value.dateRange.dateStartPrevious,
        dateEndPrevious: this.reportForm.value.dateRange.dateEndPrevious,
        department: this.reportForm.value.department === -1 ? null : this.reportForm.value.department,
        attendant: this.reportForm.value.attendant === -1 ? null : this.reportForm.value.attendant,
        channel: this.reportForm.value.channel === -1 ? null : this.reportForm.value.channel
      }
    });

    refBottom.afterClosed();
  };


  
  onGraphicSelect(value: any, label: string = 'Gráfico'): void {
    this.selectedGraphic = value;
    this.selectedGraphicLabel = label;
  }

  onListSelect(value: any, label: string = 'Lista'): void {
    this.selectedList = value;
    this.selectedListLabel = label;
  }
  
}
